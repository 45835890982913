<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import FilterTrigger from '@/shared/components/Filter/FilterTrigger.vue';
import FilterDropdown from '@/shared/components/Filter/FilterDropdown.vue';
import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { useFilters } from '@/shared/composables/filter';
import { storeToRefs } from 'pinia';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useI18n } from 'vue-i18n';
import { TestIdContext } from '@/shared/constants/DataTestIds';
import { useBaseFilterOptionsStore } from '@/shared/stores/BaseFilterOptions';
import { useMediaQuery, useScrollLock } from '@vueuse/core';

const showDropdown = ref(false);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const ratingStore = useRatingStore();
const { practiceAreasFilter } = storeToRefs(useBaseFilterOptionsStore());
const { practiceAreasQuery, setFilters } = useFilters();

const handleFilterUpdate = (selectedItems: string[]) => {
  showDropdown.value = false;
  window.scrollTo(0, 0);
  ratingStore.updateRatingList(1, selectedItems);
  setFilters(selectedItems);
};

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

const { t } = useI18n();
updateAttributeGA({
  [GA_ATRIBUTES.CATEGORY]: t('eventCategoryOnRatingPage'),
});

onMounted(() => {
  const isLocked = useScrollLock(document.body);
  watch(
    () => showDropdown.value,
    () => {
      if (useMediaQuery(`(max-width: 767px)`).value) {
        isLocked.value = showDropdown.value;
      }
    }
  );
});
</script>

<template>
  <div class="relative text-neutral-700 font-semibold text-sm">
    <FilterTrigger
      :filterCount="practiceAreasQuery.length"
      label="Rechtsgebiete"
      @toggle="toggleDropdown"
      v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickOnPracticeArea') }">
      <FilterDropdown
        :practice-areas-query="practiceAreasQuery"
        :data-attributes-GA="dataAttributesGA"
        :open="showDropdown"
        :items="practiceAreasFilter"
        title="Rechtsgebiete"
        :test-id-context="TestIdContext.PRACTICE_AREAS"
        @close="showDropdown = false"
        @update="handleFilterUpdate" />
    </FilterTrigger>
  </div>
</template>
