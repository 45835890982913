import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { ContactDetail } from '@shared/types/ContactDetail';
import { ContactHelper } from '@/shared/helper/ContactHelper';

export const initContactDetail = (contactList: ContactDetail[]) => {
  const contactDetailStore = useContactDetailsStore();
  const { modifiedContactList } = ContactHelper();

  const modifiedContacts = modifiedContactList(contactList);

  contactDetailStore.$patch((state) => {
    state.contactDetails = [...contactList];
    state.modifiedContactDetails = [...modifiedContacts];
  });
};
