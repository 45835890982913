import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { RatingQueryVariables } from '@shared/types/QueryVariables/RatingVariables';
import { ReviewListResponse } from '@shared/types/Review';

const fetchRatingData = async (query: DocumentNode, variables: RatingQueryVariables): Promise<ReviewListResponse> => {
  const client = new ApolloClient({
    link: ApolloLink.from([createHttpLink({ uri: import.meta.env.VITE_GRAPHQL_API_URL })]),
    cache: new InMemoryCache(),
  });

  return (
    await client.query({
      query,
      variables,
    })
  ).data.reviewList;
};

export default fetchRatingData;
