import gql from 'graphql-tag';
export const RatingListQuery = gql`
  query GetRatingsList($offset: Int!, $type: SubjectType!, $userId: ID!, $limit: Int!, $practiceAreas: [String!]) {
    reviewList(
      for: { type: $type, id: $userId }
      limit: $limit
      offset: $offset
      filters: { practiceAreas: $practiceAreas }
    ) {
      reviews {
        id
        rating
        displayDate
        header
        message
        reviewer
        practiceAreas
        ratedActorName
        shortUrl
        response {
          text
          actorName
          actorImage
          shortUrl
        }
      }
      filters {
        practiceAreas {
          name
          total
        }
      }
      reviewsTotal
    }
  }
`;
