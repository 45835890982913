<script lang="ts" setup>
import { provide } from 'vue';
import PageLayout from '@/layouts/PageLayout.vue';
import Breadcrumbs from '@/shared/components/Breadcrumbs.vue';
import RatingPagination from '@/modules/rating-detail/components/RatingList/RatingPagination.vue';
import { RatingList, RatingSummarySection } from '@/modules/rating-detail/components/Sections/index';
import SectionSidebar from '@/shared/components/SectionSideBar.vue';
import MobileSidebar from '@/shared/components/SectionMobileSidebar.vue';
import { PROFILE_DIRECT_MESSAGE_SOURCE } from '@/shared/constants/SourceType';
import { RATING_LIST_RATE_LINK } from '@/modules/rating-detail/constants/Links';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

provide('direct-message-source-type', PROFILE_DIRECT_MESSAGE_SOURCE);
provide('give-rating-link', RATING_LIST_RATE_LINK);
provide('give-rating-ga-label', t('eventLabelRatingDetail'));
</script>
<template>
  <PageLayout>
    <template #top>
      <Breadcrumbs />
    </template>
    <div></div>
    <template #main>
      <div class="anw-column-screen anw-grid-layout">
        <RatingSummarySection />
        <RatingList />
        <RatingPagination />
      </div>
    </template>
    <template #aside>
      <MobileSidebar class="anw-column-screen" />
      <div class="anw-column-aside hidden xl:block">
        <div class="absolute pt-6 2xl:pt-16 right-[calc((100%_-_1180px)_/_2)] w-[378px] inset-y-0">
          <SectionSidebar class="sticky top-16 z-20 xl:grid xl:gap-3" />
        </div>
      </div>
    </template>
  </PageLayout>
</template>
