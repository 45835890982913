<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useProfileStore } from '@/modules/rating-detail/stores/ProfileStore';
import { storeToRefs } from 'pinia';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { useBaseLawyerStore } from '@/shared/stores/BaseLawyerStore';
import RatingSummaryHeader from '@/modules/rating-detail/components/RatingSummary/RatingSummaryHeader.vue';
import RatingSummary from '@/shared/components/RatingSummary.vue';
import { RATING_LIST_RATE_LINK } from '@/modules/rating-detail/constants/Links';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useMediaQuery } from '@vueuse/core';

const { t } = useI18n();
const { profile } = useBaseLawyerStore();
const { profileProductId } = storeToRefs(useProfileProductId());
const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();
const { rating } = storeToRefs(useRatingStore());
const { uri } = useProfileStore();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

const isLargeScreen = useMediaQuery('(min-width: 1366px)');

onMounted(() => {
  updateAttributeGA({
    [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
    [GA_ATRIBUTES.ACTION]: isLargeScreen.value
      ? t('eventActionClickGiveRatingButton')
      : t('eventActionClickGiveRatingMobile'),
  });
});
</script>

<template>
  <section v-if="rating.isRateable" id="ratingSummary" class="mb-4 rounded bg-white p-5">
    <RatingSummaryHeader />
    <RatingSummary :rating="rating" />
    <a
      v-if="rating.isRateable"
      v-bind="dataAttributesGA"
      class="anw-btn anw-btn-secondary !text-white hover:!no-underline w-full mt-5"
      :class="{ '2xl:hidden': rating.ratingCount }"
      :href="uri + RATING_LIST_RATE_LINK">
      {{ getTranslationByProductId('rateButton', profileProductId, { n: getGenderValueForTranslation(profile.sex) }) }}
    </a>
  </section>
</template>
