import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { PaginationHelper } from '@shared/helper/PaginationHelper';
import { useProfileStore } from './ProfileStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER } from '@shared/constants/ProfileProductId';
import { RatingListQuery } from '@shared/service/graphql/rating/RatingListQuery';
import { MAX_PER_PAGE } from '@shared/constants/Pagination';
import { useBaseRatingStore } from '@/shared/stores/BaseRatingStore';
import fetchRatingData from '../Helper/UpdateRatingList';

export const useRatingStore = defineStore('rating-detail-rating-store', () => {
  const { rating, reviewList, reviewsTotal } = storeToRefs(useBaseRatingStore());

  const { userId } = storeToRefs(useProfileStore());
  const { profileProductId } = storeToRefs(useProfileProductId());
  const type = computed<'Company' | 'Lawyer'>(() => (profileProductId.value === LAWYER ? 'Lawyer' : 'Company'));

  const loading = ref(false);

  const updateRatingList = async (page: number, practiceAreas?: Array<string>) => {
    try {
      const variables = {
        offset: PaginationHelper().getOffsetFromPage(page),
        type: type.value,
        userId: userId.value,
        limit: MAX_PER_PAGE,
        practiceAreas,
      };
      loading.value = true;
      const { reviews, reviewsTotal: total } = await fetchRatingData(RatingListQuery, variables);

      reviewList.value = reviews;
      reviewsTotal.value = total;
    } catch (e) {
      console.log(e);
    } finally {
      loading.value = false;
    }
  };

  return {
    rating,
    reviewList,
    updateRatingList,
    loading,
    reviewsTotal,
  };
});
