<script lang="ts" setup>
import { AnwPagination } from '@anwalt.de/tailwind-design-system';
import { usePagination } from '@/shared/composables/pagination';
import { useFilters } from '@/shared/composables/filter';
import { useRatingStore } from '@/modules/rating-detail/stores/RatingStore';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const ratingStore = useRatingStore();
const { rating, reviewsTotal } = storeToRefs(ratingStore);
const { page, maxPage, setPageQuery, updatePagination } = usePagination(reviewsTotal.value);

const { practiceAreasQuery } = useFilters();

const handleUpdate = (nextPage: number) => {
  window.scrollTo(0, 0);
  ratingStore.updateRatingList(nextPage, practiceAreasQuery.value);
  setPageQuery(nextPage);
};

watch(
  () => reviewsTotal.value,
  () => {
    updatePagination(reviewsTotal.value);
  }
);
</script>

<template>
  <section v-if="rating.ratingCount" id="ratingPagination" class="rounded bg-white p-5">
    <AnwPagination @update:current-page="handleUpdate" :current-page="page" :number-of-pages="maxPage" />
  </section>
</template>
