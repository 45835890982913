<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SectionHeader from '@/shared/components/SectionHeader.vue';

import RatingFilter from '../Filter/RatingFilter.vue';
import { storeToRefs } from 'pinia';
import { useRatingStore } from '../../stores/RatingStore';
const { reviewsTotal } = storeToRefs(useRatingStore());
const { t } = useI18n();
</script>
<template>
  <div class="sticky top-0 pt-4 -mt-4 mb-3 bg-white z-10 border-b border-neutral-200 flex flex-col sm:flex-row">
    <SectionHeader
      :counter="reviewsTotal"
      :headline="t('ratingsHeading')"
      tag="h2"
      class="sticky top-0 bg-white flex-auto !items-start pe-4 z-10">
    </SectionHeader>
    <RatingFilter class="flex-none" />
  </div>
</template>
